html {
  background-color: #0C0F0A;
  scroll-behavior: smooth;
  color: #fff;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 6rem;
  margin: 0;
}

.navbar {
  position: fixed;
  display: flex;
  align-items: center;
  padding-left: 10vw;
  padding-right: 10vw;
  width: 80vw;
  padding-top: 2vh;
  height: 10vh;
  font-size: 2rem;
  justify-content: space-between;
  z-index: 1000;
}

.navbar-left a {
  color: #fff;
  text-decoration: none;
  font-family: 'Outfit-Bold', sans-serif;
}

.navbar-right {
  display: flex;
  font-family: 'Outfit-Medium', sans-serif;
}

.nav-items {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-items li {
  padding: 20px;
}

.nav-items li a {
  color: #fff;
  text-decoration: none;
}

.nav-items li a:hover {
  text-decoration: underline;
}

#home {
  height: 65vh;
  width: 85vw;
  position: relative;
  overflow: hidden;
}

.circle {
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}

.circle-1 {
  content: "";
  position: absolute;
  top: 50%; 
  left: -40%; 
  width: 50%; 
  height: 50%; 
  background-color: #b831e95d; 
  border-radius: 50%; 
  filter: blur(50px); 
  z-index: -1; 
}

.circle-2 {
  content: "";
  position: absolute;
  top: -20%; 
  left: 0%; 
  width: 40vw; 
  height: 40vw; 
  background-color: #008a9c79; 
  border-radius: 50%; 
  filter: blur(100px); 
  z-index: -1; 
}

.circle-3 {
  content: "";
  position: absolute;
  top: 20%; 
  left: 80%; 
  width: 40vw; 
  height: 50vw; 
  background-color: #7831e98c; 
  border-radius: 50%; 
  filter: blur(100px); 
  z-index: -1; 
}

.hero-text {
  color: #fff;
  font-family: 'Outfit-Bold', sans-serif;
  padding-top: 30vh;
  padding-bottom: 5vh;
  padding-left: 10vw;
  width: 85vw;
  padding-right: 5vw;
  overflow: break-word;
}

.highlighted-hero-text {
  margin-bottom: 5rem;
}

.about-button {
  background-color: #7731E9;
  color: #fff;
  border: none;
  font-family: 'Outfit-Medium', sans-serif;
  text-align: center;
  text-decoration: none;
  border-radius: 2rem 2rem;
  margin-top: 5rem;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.593);

  font-size: 1.5rem;
  letter-spacing: 0.05rem;
  padding: 1rem 1.5rem;
  margin: 1vh 0vw;
}

.highlighted {
  color: #7731E9;
}

#about {
  background-color: #ffffff9a;
  display: inline-flexbox;
  min-height: 100vh;
  height: fit-content + 5vh;
  width: 80vw;
  color: #000000;
  padding-top: 5vh;
  padding-left: 10vw;
  padding-right: 10vw;
  position: relative;
  overflow: hidden;
}

.about-header, .projects-header {
  font-family: 'Outfit-Bold', sans-serif;
  font-size: 3rem;
  margin-bottom: 2vh;
}

#about h3 {
  font-family: 'Outfit-SemiBold', sans-serif;
  font-size: 2rem;
  margin-bottom: 2vh;
}

#about p {
  font-family: 'SourceSans3-Regular', sans-serif;
  font-size: 1.5rem;
}

.technology-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-evenly;
  background-color: #ffffff;
  height: fit-content;
  border-radius: 1rem 1rem;
  padding: 2vh 2vw;
  border: none;
  margin-bottom: 5vh;
  box-shadow: 5px 5px 5px #0000002c;
}

.technology-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.rounded-box {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  background-color: #cecece42;
  padding: 10px;
  text-align: center;
}

.svg {
  padding-top: 5px;
}

.rounded-box p {
  color: #000000;
  margin-top: 10px;
  font-family: 'Outfit-Medium', sans-serif;
}

#projects {
  color: #fff;
  min-height: 90vh;
  height: fit-content;
  width: 80vw;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: 5vh;
  padding-bottom: 5vh;
  position: relative;
  overflow: hidden;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  background-color: #ffffff9a;
  border-radius: 2rem;
  margin-top: 4vh;
  min-height: 70vh;
  padding-top: 2.5vh;
  padding-bottom: 2.5vh;
  justify-content: center;
}

.project {
  display: flex;
  flex-wrap: wrap;
  max-width: 35%;
  min-width: 250px;
  justify-content: left;
  align-self: center;
  background-color: #ffffff;
  min-height: 50vh;
  max-height: fit-content;
  border-radius: 2rem;
  padding: 2vh 2vw;
  border: none;
  margin: 2.5vh 2.5vw;
  flex-wrap: space-evenly;
  box-shadow: 5px 5px 5px #0000002c;
  color: #000000;
  padding-bottom: 5vh;
}

.project-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 0;
  margin: 0;
}

#projects img {
  max-width: 100%;
  min-width: 200px;
  height: auto;
  align-self: center;
  margin: 0;
}

.project-info {
  padding-left: 2.5vw;
  padding-right: 2.5vw;
}

.project-info h3 {
  font-family: 'Outfit-Bold', sans-serif;
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 0;
}

.project-stack {
  font-family: 'Outfit-Medium', sans-serif;
  font-size: 1rem;
}

.project-info p {
  font-family: 'SourceSans3-Regular', sans-serif;
  margin-top: 1vh;
  margin-bottom: 3vh;
}

.project-button {
  background-color: #7731E9;
  color: #fff;
  border: none;
  font-family: 'Outfit-Medium', sans-serif;
  text-align: center;
  text-decoration: none;
  border-radius: 2rem 2rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.593);
  font-size: 1rem;
  padding: 0.5rem 1rem;
  margin-top: 1vh;
  margin-right: 1vw;
  margin-bottom: 3vh;
}

#contact-container {
  min-height: 80vh;
  height: fit-content;
  width: 80vw;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: 5vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-header {
  font-family: 'Outfit-Bold', sans-serif;
  font-size: 3rem;
  align-self: first baseline;
  margin-bottom: 2vw;
}

.contact-cta {
  font-family: 'Outfit-Bold', sans-serif;
  font-size: 4rem;
  margin-top: 2vh;
  margin-bottom: 2vw;
}

#contact-form-container {
  height: fit-content;
  background-color: #ffffff9a;
  border-radius: 2rem;
  width: 80vh;
  padding-right: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 5vh;
  }

#name {
  margin-top: 3vh;
}

input, textarea {
  padding: 0.5rem;
  margin-bottom: 1.5vh;
  margin-top: 1.5vh;
  font-family: 'SourceSans3-Regular', sans-serif;
  font-size: 1.2rem;
  width: 85%;
  border: none; 
  background-color: #ffffff9a;
  padding: 0.5rem;
  border-radius: 0.8rem 0.8rem;
  resize: none;
}

input, textarea, #submit-button {
  margin-bottom: 1.5vh;
  margin-top: 1.5vh;
}

#submit-button {
  background-image: linear-gradient(315deg, #27aab4bb, #8c25b2c0);  color: #fff;
  border: none;
  font-family: 'Outfit-Medium', sans-serif;
  text-align: center;
  text-decoration: none;
  border-radius: 1rem 1rem;
  margin-bottom: 3vh;
  align-self: center;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.268);

  font-size: 1.5rem;
  letter-spacing: 0.05rem;
  padding: .7rem 5rem;
  margin: 2vh 0vw;
  cursor: pointer;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(315deg, #27aab4bb, #8c25b2c0);  color: #fff;
  width: 100vw;
  height:15vh;
}

.link-item {
  padding: 25px;
}

@media (max-width: 1100px) {
  h1 {
    font-size: 4.5rem;
  }

  .contact-cta {
    font-size: 3.5rem;
  }
}

@media (max-width: 870px) {
  #contact-form-container {
    width: 80vw;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 4rem;
  }

  .navbar {
    font-size: 1.5rem;
  }

  .contact-cta {
    font-size: 3rem;
    padding-bottom: 3vh;
  }
}

@media (max-width: 500px) {
  .rounded-box {
    height: 150px;
    width: 100px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 3rem;
  }

  .navbar {
    font-size: 1.2rem;
  }

  .nav-items li {
    padding: 10px;
  }

  .hero-text {
    padding-left: 10vw;
    width: 85vw;
  }

  .contact-cta {
    font-size: 2rem;
    padding-bottom: 4vh;
  }

  #submit-button {
    padding: .7rem 3rem;
  }
}

@media (max-width: 380px) {
  .navbar {
    font-size: 1rem;
  }
}

@media (max-width: 305px) {
  h1 {
    font-size: 3rem;
  }

  .navbar {
    font-size: 1rem;
    overflow: scroll;
    scrollbar-width: thin;
  }

  .hero-text {
    padding-top: 20vh;
    padding-bottom: 15vh;
    width: 85vw;
    margin-bottom: 0rem;
  }

  .highlighted-hero-text {
    margin-bottom: 4rem;
  }
}